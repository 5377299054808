@import "../../../../../assets/sass/variables";

h5 {
  margin: 0;
}

.no-data {
  font-style: italic;
  font-weight: bold;
  height: 9rem;
}

.dashboard-tabs-top {
  margin-top: 10px;
  // .ant-tabs-nav {
  //   margin-top: 10px !important;
  // }
}

.ant-pagination {
  @media only screen and (max-width: 825px) {
    position: unset !important;
    left: unset !important;
    right: unset !important;
    margin-right: auto;
  }
}

.lineChartValues {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 1.1vw;
  .customArrowUp {
    margin-top: 7px;
    font-size: 1.5vw;
    transform: rotate(-45deg);
    -ms-transform: rotate(-45deg); /*  for IE  */
    -webkit-transform: rotate(-45deg);
    display: inline-block;
  }
  .customArrowDown {
    margin-top: -7px;
    font-size: 1.5vw;
    transform: rotate(135deg);
    -ms-transform: rotate(135deg); /*  for IE  */
    -webkit-transform: rotate(135deg);
    display: inline-block;
  }
}

.predictedValues {
  font-weight: bold;
  color: #000000a1;
  text-align: left;
  font-size: 0.8vw;
}

.pridictedDays {
  text-shadow: 0px 0px, 0px 0;
  font-size: 0.85vw;
  color: red;
  margin-bottom: 0px !important;
  white-space: normal;
}

.tinyChart {
  width: 22vw;
  height: 8rem;
  // svg.recharts-surface {
  //   height: 8.5rem;
  // }
  // tspan,
  // text {
  //   font-size: 9px;
  // }
}

.customTinyChart {
  margin-bottom: 5px;
  width: 20vw;
  height: 8rem;
  // svg.recharts-surface {
  //   height: 10rem;
  // }
  // tspan,
  // text {
  //   font-size: 9px;
  // }
}
.customChartPopup {
  width: 50vw;
  min-width: 500px;
  height: 27rem;
  padding: 18px;
  svg.recharts-surface {
    height: 27rem;
    margin-left: -15px;
    overflow: visible;
  }
  tspan,
  text {
    font-size: 10px;
    font-weight: bold;
  }
}

.customBarChart {
  margin-bottom: 10px;
  svg.recharts-surface {
    height: 9.5rem;
  }
  tspan {
    font-size: smaller;
    font-weight: bold;
  }
}

.header-action-btns {
  display: flex;
  align-items: center;
  margin-top: 5px;
}
.filterCategory {
  label.ant-radio-wrapper.ant-radio-wrapper-checked {
    font-weight: bold;
    color: $primary-bg-color;
  }
  .ant-radio-wrapper {
    // span.ant-radio + *{
    //   padding-left: 6px !important;
    //  padding-right: 6px !important;
    // }
    .ant-radio {
      &:hover:not(.ant-radio-disabled):not(.ant-radio-checked) {
        .ant-radio-inner {
          border-color: #a5a5a5;
          &:after {
            background: white;
          }
        }
      }
      .ant-radio-inner {
        border-color: #a5a5a5;
        height: 14px;
        width: 14px;
        &::after {
          background: white;
          height: 10px;
          left: 1px;
          top: 1px;
          width: 10px;
        }
      }
      &.ant-radio-checked {
        .ant-radio-inner {
          border-color: $primary-bg-color !important;
          background-color: white;
          &::after {
            background: $primary-bg-color;
            border-color: $primary-bg-color;
            color: $primary-bg-color;
          }
        }
      }
    }
  }
}

.tinyChart-tooltip {
  background-color: white;
  border: 0.5px solid #e4e4e4;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 10px;
  font-size: 12px;
  text-align: left;
  font-weight: bold;
}

.ratingCard {
  width: max-content;
  border-radius: 4px 4px 0 0 !important;
  font-size: 1.4vw;
  font-weight: bolder !important;
  text-shadow: 0px 0px, 0px 0;
  margin-bottom: unset !important;
  box-shadow: 0px 0px 6px 3px #f5f5f5;
  .ant-card-body {
    padding: 0.6rem 1.5rem 0.4rem !important;
  }
  .ant-card-body {
    &::after {
      display: none;
    }
    &::before {
      display: none;
    }
  }
}

.benchmarkCustomCard {
  font-size: 1.1vw;
  text-shadow: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.trackedItemsDashboard {
  margin-top: unset !important;
}

.dashboardListing {
  .ant-table table {
    border-spacing: 0 10px !important;
  }
  .ant-table-wrapper .ant-table-tbody > tr > td {
    border: unset !important;
  }

  .ant-table-wrapper {
    .ant-table-thead tr > th {
      cursor: auto;
    }
    .ant-table tr td {
      border-bottom: 1px solid #ebebeb;
      border-top: 1px solid #ebebeb;
      &:first-child {
        border-top-left-radius: 8px !important;
        border-bottom-left-radius: 14px !important;
        border-left: 1px solid #ebebeb;
      }
      &:last-child {
        box-shadow: 1px 17px 44px rgba(3, 2, 41, 0.07);
        border-top-right-radius: 8px !important;
        border-bottom-right-radius: 14px !important;
        border-right: 1px solid #ebebeb;
      }
    }
    .ant-table-tbody
      > tr:not(.ant-table-expanded-row):not(.ant-table-row-selected) {
      box-shadow: 0 0 6px #e7e7e7;
      &:hover {
        box-shadow: 0 0 12px #afafaf !important;
      }
    }
  }
  .ant-table-wrapper .ant-table tr th:first-child,
  .ant-table-wrapper .ant-table tr td:first-child {
    padding-left: 1.92857rem;
  }
}

.shadow-none {
  .ant-table tr td:last-child {
    box-shadow: none !important;
  }
}

.pagination {
  font-weight: bold;
  button {
    border: 1px solid #d0d0d0;
    background: transparent;
    color: #000000;
    height: 25px;
  }
  .left {
    border-radius: 5px 0 0 5px;
  }
  .right {
    border-radius: 0 5px 5px 0;
  }
  .currentPage {
    border: 1px solid #d0d0d0;
    padding: 3px 9px 4px;
  }
  .ant-btn {
    margin-right: 0;
    margin-left: 10px;
    width: 18%;
    box-shadow: none;
    padding: 0.7rem 0.7rem !important;
    border-radius: 4px;
  }
  .ant-btn:focus,
  .ant-btn:hover {
    background: transparent;
    color: #000000;
    border: 1px solid #d0d0d0;
  }
  .anticon {
    color: #117b70;
    font-size: 13px !important;
  }
}

.dashboardDropdown {
  display: inline-flex;
  align-items: center;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  font-size: 0.9rem;
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 25px !important;
    background: white;
  }
}

.pridictedDays {
  font-weight: bolder;
  margin-bottom: 0px !important;
}

// .ant-tooltip-arrow {
//   display: none;
// }

span.ant-tooltip-arrow-content {
  background: white;
}

.ant-tooltip-inner {
  min-width: max-content;
  min-height: max-content;
  color: black;
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.reports__overall-status {
  .previousRatingCard > h6,
  .currentRatingCard > h6 {
    margin-top: 0;
    text-align: center;
  }
  .watchlistDays {
    text-align: center;
  }
  .watchlistCard > .ant-card-body {
    text-align: center;
  }
  .predictionSmallCard {
    text-align: center;
    margin: 0 1px;
  }
  .predictionRatings,
  .predictionChartContainer {
    margin-top: unset !important;
    text-align: center;
  }
  .predictionChartContainer {
    width: 55%;
  }
  .historicalChartScale {
    right: 20px;
  }
  .viewLargeGraph {
    width: calc(100% - 123px);
    left: 101px;
  }
  .historicalChart:not(.historicalChartPopup)
  // , .watchlistPredictionColum > div
  {
    position: relative;
    transform: translateY(-73%);
    height: 120px;
  }
  .historicalChartLabels {
    margin: 4px 0px 0px 63px !important;
    p {
      padding: 2px 5px;
    }
  }
}

.rating-count {
  margin-top: 15px;
  font-size: 12px;
  .rating-title {
    font-weight: bold;
  }
  .rating-count-lable {
    width: max-content;
    min-width: 70px;
    height: 24px;
    display: flex;
    align-items: center;
    border: 1px solid;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 2px 10px 2px 2px;
    justify-content: space-between;
    .ratingGradeBg {
      color: white;
      font-weight: bold;
      text-align: center;
      width: 20px;
      height: 20px;
      border-radius: 10px;
    }
  }
}

.ant-breadcrumb {
  margin-top: 0 !important;
  font-size: 12px !important;
  .breadcrum-bg {
    border-top: 1px solid #79a4a2;
    border-bottom: 1px solid #79a4a2;
  }
  .breadcrumb-item {
    margin-left: 0px;
    padding: 0 15px 0 30px;
  }
  .breadcrumb-values {
    display: inline-flex;
    flex-direction: column;
    align-content: flex-start; 
    max-width: 100%;
    padding-right: 5px;
  }
  .breadcrmb-item-grade {
    padding: 3px 8px;
    color: white;
    margin-right: 8px;
    font-weight: bold;
    border-radius: 4px;
    width: 25px;
  }
  .breadcrumb-level {
    color: #2b3b4a;
    font-size: 11px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  a {
    color: black;
    display: flex;
    align-items: center;
    overflow: hidden;
    max-width: 100%;
    // height: 55px;
  }
  > span:not(:last-child) {
    .breadcrmb-item-grade {
      transform: translateY(0);
      position: relative;
    }
  }
  > span {
    height: 55px;
    min-width: 0px;
    display: flex;
    // min-width: 200px;
    position: relative;
    justify-content: center;
    flex-grow: 1;
    flex-basis: 20%;
    flex-shrink: 1;
    max-width: max-content;
    :not(.breadcrumb-lable) .breadcrumb-values {
      max-width: calc(100% - 25px);
      margin-left: 0px;
      strong{
        max-width: 100%;
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-right: 10px;
      }
      .breadcrumb-level{
        max-width: 100%;
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-right: 10px;
      }
    }
    .breadcrumb-item:not(.breadcrumb-lable) {
      transform: translateY(0);
    }
  }
  > span:last-child {
    .ant-breadcrumb-separator {
      display: inline-block;
      .arrow {
        margin-right: -8px;
        padding: 15px;
        transform: translateY(25px) rotate(-45deg) skew(-13deg, -13deg) translateX(15px)
      }
    }
  }
  > span.activeItem {
    // background: #1b998f;
    .activeBreadcrumb {
      background: #1b998f;
      .breadcrmb-item-grade {
        transform: unset;
        position: relative;
      }
    }
  }
  .activeItem {
    color: white !important;
    .breadcrumb-level {
      color: white;
    }
    .arrow {
      background-color: #1b998f;
    }
  }
  .ant-breadcrumb-separator {
    margin: 0;
    width: 0px;
    // background-color: white;
  }
  .arrow {
    transform: translateY(23px) rotate(-45deg) skew(-13deg, -13deg)
      translateX(13px);
    margin-left: -23px;
    position: relative;
    z-index: 1;
  }
}

.breadcrumb-navigation-tooltip .ant-tooltip-content{
  display: flex;
  justify-content: flex-end;
}
.breadcrumb-navigation-tooltip.level-0,.breadcrumb-navigation-tooltip.level-1,.breadcrumb-navigation-tooltip.level-2{
  .ant-tooltip-content{
  display: flex;
  justify-content: flex-start;
  }
}